<template>
  <div class="about">
    <v-parallax height="300" :src="require('../assets/about-bg.jpg')" class="about-parallax image-header mx-n5 mb-10">
      <v-container class="jumbotron">
        <v-row>
          <v-col>
            <h1 class="entry-title desktop-only gs_reveal d-none d-sm-block">
              We offer the ultimate agribusiness<br />
              and commodity solutions in a<br />
              more holistic way.
            </h1>
            <h1 class="entry-title desktop-only gs_reveal d-sm-none">
              We offer the ultimate<br />
              agribusiness and commodity<br />
              solutions in a more holistic way.
            </h1>
          </v-col>
        </v-row>
      </v-container>
    </v-parallax>
    <v-container>
      <h1 class="mb-3 gs_reveal">Universal Komoditi Nusantara</h1>
      <p class="gs_reveal grey--text">
        Universal Komoditi Nusantara relies on the expertise of professionals committed to working with us to carry out
        Universal Komoditi Nusantara’s missions and vision, creating more than 500 jobs in the company, of which 99% of
        the employee are Indonesian. Our company has been working to respond to the ever-increasing demand for starch
        and sweetness in South Asia.
      </p>
      <p class="mb-12 gs_reveal grey--text">
        Dedication and commitment are at the heart of our action and teams that drive by the look for increased impact.
        We deploy innovative solutions outside the box for sustainable change.
      </p>
      <v-row>
        <v-col cols="12" md="6" class="d-flex">
          <div class="paragraph-icon d-flex mr-7 gs_reveal">
            <img :src="require('@/assets/ic_manufactured.svg')" />
          </div>
          <div>
            <h3 class="gs_reveal">Self-Manufactured</h3>
            <p class="gs_reveal grey--text">
              The production process, production facilities, and the final product are careful. Our product is made by
              referring to CPOB and GMP standards applied in the food and beverage industry. In addition to producing
              the best quality products to be marketed in Indonesia and later exported to the whole world, Universal
              Komoditi Indonesia also carries out the business of trading goods and services in general.
            </p>
          </div>
        </v-col>
        <v-col cols="12" md="6" class="d-flex">
          <div class="paragraph-icon d-flex mr-7 gs_reveal">
            <img :src="require('@/assets/ic_solution.svg')" />
          </div>
          <div>
            <h3 class="gs_reveal">Solution Oriented</h3>
            <p class="gs_reveal grey--text">
              We process cornstarch, and the derived products are applicable as raw materials in various industries,
              including food & beverage, feed, textile, adhesive, paper, and others. Our commitment is to providing our
              customers good quality products with competitive prices, on-time delivery, and throughout the years. We
              continuously improved our quality and service to the highest standard.
            </p>
          </div>
        </v-col>
      </v-row>
      <v-divider class="mt-5 mb-10" />
      <v-row class="team mb-5">
        <v-col cols="12" md="6" class="pr-16">
          <h1 class="gs_reveal gs_reveal_fromLeft">Our team</h1>
          <p class="grey--text gs_reveal gs_reveal_fromLeft">
            Universal Komoditi Nusantara has skilled staff working in Indonesia. Most of our teams are based in
            professional programs, directly assisting national governments and factories. Specialized teams provide
            coordination and direct, expert support to the field, strengthening our impact on the global stage.
          </p>
          <p class="grey--text gs_reveal gs_reveal_fromLeft">
            At Universal Komodity Indonesia, we are driven by shared standards of excellence, guided by the same
            integrity, and motivated by a simultaneous ambition to improve lives.
          </p>
        </v-col>
        <v-col cols="6" sm="4" md="2" class="gs_reveal gs_reveal_fromRight">
          <img :src="require('@/assets/david.jpg')" />
          <div>David Ricardo</div>
          <div class="caption grey--text">Main Director</div>
        </v-col>
        <v-col cols="6" sm="4" md="2" class="gs_reveal gs_reveal_fromRight">
          <img :src="require('@/assets/farley.jpg')" />
          <div>Farley Richie Leardo</div>
          <div class="caption grey--text">Commissioner</div>
        </v-col>
        <v-col cols="6" sm="4" md="2" class="gs_reveal gs_reveal_fromRight">
          <img :src="require('@/assets/ina.jpg')" />
          <div>Ina Faudiani</div>
          <div class="caption grey--text">Director</div>
        </v-col>
      </v-row>
    </v-container>
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default {
  components: {
    Footer,
  },
  methods: {
    animateFrom(elem, dir) {
      const direction = dir || 1;
      let x = 0;
      let y = direction * 100;
      const element = elem;
      if (element.classList.contains('gs_reveal_fromLeft')) {
        x = -100;
        y = 0;
      } else if (element.classList.contains('gs_reveal_fromRight')) {
        x = 100;
        y = 0;
      }
      element.style.transform = `translate(${x}px, ${y}px)`;
      element.style.opacity = '0';
      gsap.fromTo(
        element,
        { x, y, autoAlpha: 0 },
        {
          duration: 1.25,
          x: 0,
          y: 0,
          autoAlpha: 1,
          ease: 'expo',
          overwrite: 'auto',
        }
      );
    },

    hide(elem) {
      gsap.set(elem, { autoAlpha: 0 });
    },
  },
  mounted() {
    window.scrollTo(0, 0);

    gsap.registerPlugin(ScrollTrigger);

    gsap.utils.toArray('.gs_reveal').forEach((elem) => {
      this.hide(elem); // assure that the element is hidden when scrolled into view

      ScrollTrigger.create({
        trigger: elem,
        onEnter: () => {
          this.animateFrom(elem);
        },
        onEnterBack: () => {
          this.animateFrom(elem, -1);
        },
        onLeave: () => {
          this.hide(elem);
        }, // assure that the element is hidden when scrolled into view
      });
    });
  },
};
</script>

<style scoped>
@font-face {
  font-family: 'Museo Sans 500';
  src: url('../assets/MuseoSans_500.otf');
}
.entry-title {
  font-family: 'Museo Sans 500';
  font-weight: 500;
  font-size: 32px;
  color: #fff;
  line-height: 40px;
  margin: 0;
  text-shadow: 2px 2px 6px #333333;
  opacity: 0.9;
  position: relative;
}

.entry-title:before {
  content: '';
  position: absolute;
  top: 2px;
  left: -20px;
  background: url('../assets/quote-yellow.png') no-repeat;
  width: 15px;
  height: 15px;
}

.entry-title:after {
  content: '';
  display: inline-block;
  background: url('../assets/quote-yellow.png') no-repeat;
  width: 15px;
  height: 15px;
  margin-left: 5px;
  margin-bottom: 8px;
  opacity: 1;
}

.paragraph-icon {
  background: #c33c59;
  min-width: 64px;
  width: 64px;
  height: 64px;
  align-items: center;
  justify-content: center;
}

.paragraph-icon img {
  width: 32px;
  height: 32px;
}

.team img {
  width: 100%;
}

@media (max-width: 595px) {
  .v-parallax.about-parallax {
    height: 250px !important;
  }
  .jumbotron {
    padding-left: 20px;
    padding-right: 20px;
  }
  .entry-title {
    text-align: center;
    font-size: 1.5rem;
    /* font-size: 22px; */
    text-shadow: 3px 3px 4px rgb(0 0 0 / 70%);
    line-height: 27px;
  }
  .entry-title:before {
    position: static;
    display: inline-block;
    width: 15px;
    height: 15px;
    /* margin-right: 5px; */
    margin-bottom: 8px;
    opacity: 1;
  }
  .entry-title:after {
    margin-bottom: 4px;
  }
  .about .mb-10 {
    margin-bottom: 28px !important;
  }
  .about .pr-16 {
    padding-right: 16px !important;
  }
}
</style>
